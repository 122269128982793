<script lang="ts">
  import { Router, Route, navigate } from 'svelte-routing';
  import { onMount } from 'svelte';
  import Layout from './shindo/layout/Layout.svelte';
  import Home from './shindo/pages/home/Home.svelte';
  import Login from './shindo/pages/auth/Login.svelte';
  import Register from './shindo/pages/auth/Register.svelte';
  import e404 from './shindo/hub/404.svelte';
  import Status from './shindo/pages/status/Status.svelte';
  // import Post from './shindo/post/Post.svelte';
  import Hub from './shindo/hub/Hub.svelte';
  import Dialog from './shindo/pages/dialog/Dialog.svelte';
  import Privacy from './shindo/pages/info/Privacy.svelte';
  import Terms from './shindo/pages/info/Terms.svelte';
  import Contact from './shindo/pages/info/Contact.svelte';
  import About from './shindo/pages/info/About.svelte';
  import Countries from './shindo/pages/countries/Countries.svelte';
  import Country from './shindo/pages/countries/Country.svelte';
  import Cities from './shindo/pages/cities/Cities.svelte';
  import City from './shindo/pages/cities/City.svelte';
  import Shindo from './shindo/pages/info/Shindo.svelte';
  import SEO from './lib/components/SEO.svelte';
  import ProtectedRoute from './lib/components/ProtectedRoute.svelte';
</script>

<Router>
  <Layout>
    <!-- Public routes -->
    <Route path="/" component={Home} />
    <Route path="/login" component={Login} />
    <Route path="/register" component={Register} />
    <Route path="/privacy" component={Privacy} />
    <Route path="/terms" component={Terms} />
    <Route path="/about" component={About} />
    <Route path="/contact" component={Contact} />
    <Route path="/shindo" component={Shindo} />

    <!-- Protected routes -->
    <Route path="/hub" let:params>
      <ProtectedRoute path="/hub" component={Hub} />
    </Route>
    <Route path="/dialog" let:params>
      <ProtectedRoute path="/dialog" component={Dialog} />
    </Route>
    <Route path="/status" let:params>
      <ProtectedRoute path="/status" component={Status} />
    </Route>
    <Route path="/countries" let:params>
      <ProtectedRoute path="/countries" component={Countries} />
    </Route>
    <Route path="/countries/:name" let:params>
      <ProtectedRoute path="/countries/:name" component={Country} />
    </Route>
    <Route path="/cities" let:params>
      <ProtectedRoute path="/cities" component={Cities} />
    </Route>
    <Route path="/cities/:name" let:params>
      <ProtectedRoute path="/cities/:name" component={City} />
    </Route>

    <!-- Public 404 route - should be last -->
    <Route path="*" component={e404} />
  </Layout>
</Router>
