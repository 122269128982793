<script lang="ts">
  import { t } from '../../../translations/translations';
  import { Link } from 'svelte-routing';
  let isNavOpen = false;

  const handleLinkClick = () => {
    isNavOpen = false;
  };
</script>

<footer class="py-2 text-center bg-footer-dark text-txt-body-dark text-sm font-light tracking-wider">
  <div class="container mx-auto flex flex-wrap justify-center gap-4 items-center">
    <span class="text-sm">{$t.footer.copyright}</span>
    <div class="relative">
      <button
        on:click={() => isNavOpen = !isNavOpen}
        class="hover:text-gray-300 flex items-center gap-1 font-bold text-base"
      >
        <svg class={`w-4 h-4 transition-transform ${isNavOpen ? 'rotate-180' : 'rotate-0'}`}
             fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
        </svg>
      </button>
      {#if isNavOpen}
        <nav class="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 bg-form-light dark:bg-form-dark border border-form-border-light dark:border-form-border-dark rounded-lg py-2 w-[200px] z-50">
          <div class="flex flex-col text-left">
            <!-- <Link to="/terms" on:click={handleLinkClick} class="px-4 py-2 hover:bg-form-input-light dark:hover:bg-form-input-dark text-txt-body-light dark:text-txt-body-dark">{$t.footer.terms}</Link>
            <Link to="/privacy" on:click={handleLinkClick} class="px-4 py-2 hover:bg-form-input-light dark:hover:bg-form-input-dark text-txt-body-light dark:text-txt-body-dark">{$t.footer.privacy}</Link>
            <Link to="/contact" on:click={handleLinkClick} class="px-4 py-2 hover:bg-form-input-light dark:hover:bg-form-input-dark text-txt-body-light dark:text-txt-body-dark">{$t.footer.contact}</Link>
            <Link to="/about" on:click={handleLinkClick} class="px-4 py-2 hover:bg-form-input-light dark:hover:bg-form-input-dark text-txt-body-light dark:text-txt-body-dark">{$t.footer.about}</Link> -->
            <Link to="/shindo" on:click={handleLinkClick} class="px-4 py-2 hover:bg-form-input-light dark:hover:bg-form-input-dark text-txt-body-light dark:text-txt-body-dark">Shindo</Link>
          </div>
        </nav>
      {/if}
    </div>
  </div>
</footer>
