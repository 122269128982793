import { type LanguageCode } from "./translations";

// Define the type for shindo translations
export type ShindoTranslation = {
    title: string;
    text: string;
    heart_title: string;
    heart: string;
    spirit_title: string;
    spirit: string;
    way_title: string;
    way: string;
};

// Export shindo translations for each language
export const shindoTranslations: Record<LanguageCode, ShindoTranslation> = {
    en: {
        title: "Shindo",
        text: "Shindo represents the harmony of Heart (心 - Kokoro), Spirit (魂 - Tamashii), and Way (道 - Michi). It embodies the emotional core of creation, the passionate drive for excellence, and the disciplined journey of continuous growth and innovation.",
        heart_title: "Heart",
        heart: "The emotional and intuitive core of everything you create. Balancing empathy and understanding to guide decisions and creations. Building systems that resonate not just with logic but with humanity.",
        spirit_title: "Spirit",
        spirit: "The drive, energy, and passion fueling your journey. The unshakable essence of your creativity and problem-solving. A commitment to transcend mediocrity and pursue mastery.",
        way_title: "Way",
        way: "The journey and philosophy guiding your craft and life. A focus on progress, discipline, and alignment with your purpose. Innovating and refining while staying true to your values."
    },
    ru: {
        title: "Шиндо",
        text: "Шиндо воплощает гармонию Сердца (心 - Кокоро), Духа (魂 - Тамаши) и Пути (道 - Мичи). Это эмоциональная основа творчества, страстное стремление к совершенству и дисциплинированный путь непрерывного роста и инноваций.",
        heart_title: "Сердце",
        heart: "Эмоциональная и интуитивная основа всего, что вы создаете. Баланс эмпатии и понимания для руководства решениями и творениями. Создание систем, которые резонируют не только с логикой, но и с человечностью.",
        spirit_title: "Дух",
        spirit: "Движущая сила, энергия и страсть, питающие ваш путь. Непоколебимая сущность вашего творчества и решения проблем. Стремление превзойти посредственность и достичь мастерства.",
        way_title: "Путь",
        way: "Путь и философия, направляющие ваше ремесло и жизнь. Фокус на прогрессе, дисциплине и соответствии вашей цели. Инновации и совершенствование при сохранении верности своим ценностям."
    },
};